<!--创建问诊单  填写基本信息-->
<template>
	<div class="consu-container">
		<v-consultation-header></v-consultation-header>
		<div class="consultation wrap">
			<div class="consultation-content">
				<div class="basic-bg">
					<div class="patient">
						<h1>患者信息</h1>
						<ul>
							<li>
								<label>姓名</label>
								<span>{{patientInfo.name}}</span>
							</li>
							<li>
								<label>性别</label>
								<span>{{patientInfo.gender === 1 ? '男' : '女'}}</span>
							</li>
							<li>
								<label>年龄</label>
								<span>{{patientInfo.age}}</span>
							</li>
							<li>
								<label>手机号</label>
								<span>{{patientInfo.mobilePhone}}</span>
							</li>
						</ul>
					</div>
					<ul class="basic-info">
						<li>
							<h1>身高体重</h1>
							<div class="wh flex f-ai-c">
								<label>身高</label>
								<div class="val val1 flex f-ai-c">
									<input v-model="patientInfo.height" type="number">
									<span>cm</span>
								</div>
								<label>体重</label>
								<div class="val flex f-ai-c">
									<input v-model="patientInfo.weight" type="number">
									<span>kg</span>
								</div>
							</div>
						</li>
						<li>
							<h1>
								<i>*</i>
								疾病名称或症状
							</h1>
							<van-field @input="onChangeText" v-model="form1.diseaseNameOrzz" placeholder="例：糖尿病2年或呕吐3天（2-20字）" class="input" />
						</li>
						<li>
							<h1>
								<i>*</i>
								病情描述
							</h1>
							<van-field @input="onChangeText" v-model="form1.diseaseDescription" placeholder="详细描述您的病情与症状(最多100字)" class="input" />
						</li>
					</ul>
				</div>
				<div class="middle flex f-jc-c f-ai-c">
					<i class="left"></i>
					<span>以下信息将帮助医生更准确地了解您的病情</span>
					<i class="right"></i>
				</div>
				<div class="basic-bg">
					<ul class="basic-info pt10">
						<li>
							<h1>服用药物</h1>
							<van-field @input="onChangeText" v-model="form1.takedMedicine" placeholder="请填写当前是否正在服用药物" class="input" type="text" />
						</li>
						<li>
							<h1>过敏史</h1>
							<van-field @input="onChangeText" v-model="form1.guominshi" placeholder="药物、食物及其他；若没有，请填写“无”" class="input" type="text" />
						</li>
						<li>
							<h1>既往病史</h1>
							<van-field @input="onChangeText" v-model="form1.jiwangbingshi" placeholder="以前得过什么病；若没有，请填写“无”" class="input" type="text" />
						</li>
						<li>
							<h1>婚育史</h1>
							<van-field @input="onChangeText" type="textarea" v-model="form1.hunyushi" placeholder="已婚、未婚；女性完善（未生育、备孕期、怀孕期、已生育）" rows="3" class="input"></van-field>
						</li>
					</ul>
				</div>
			</div>
			<div class="btn flex f-jc-c f-ai-c">
				<button :class="{'enable' : canNext}" @click="nextStep">下一步</button>
			</div>
		</div>
		<van-popup v-model="showPopup" position="bottom" style="background: #F3F3F3;">
			<ul class="popup-btns">
				<li @click="toConsultation()">新建问诊单</li>
				<li v-if="hasHistory" @click="toConsultation(1)">引用历史问诊单</li>
				<li v-else class="disabled">引用历史问诊单</li>
				<!-- <li @click="toConsultation(2)">跳过</li> -->
			</ul>
			<div class="cancel" @click="hidePopup">取消</div>
		</van-popup>
		<van-popup v-model="showGoBack" class="flex f-ai-c back-dialog">
			<h1>提示</h1>
			<span>是否确认离开此页面？离开后已填写的信息将无法被保存。</span>
			<div class="back-btns">
				<span @click="closeDialog(1)">确定离开</span>
				<span @click="closeDialog()">我再想想</span>
			</div>
		</van-popup>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { consultationHist } from '@/request/api/user';
import vConsultationHeader from './consultation-header';

export default {
	name: 'CosulationOne',
	data () {
		return {
			canNext: false,
			showPopup: false,
			showGoBack: false,
			hasHistory: false, // 当前就诊人是否有历史问诊单
			patientInfo: {
				id: 0,
				name: '',
				gender: '',
				age: 16,
				mobilePhone: '',
				height: 0,
				weight: 0
			},
			form1: {
				diseaseNameOrzz: '',
				diseaseDescription: '',
				takedMedicine: '',
				guominshi: '',
				jiwangbingshi: '',
				hunyushi: ''
			},
			historyData: {}
		};
	},
	components: {
		vConsultationHeader
	},
	created () {
		if (this.patientList && this.patientList.length) {
			this.patientInfo = this.patientList.find(p => p.id === Number(this.$route.query.patientId));
		} else {
			this.$root.replace('/');
		}
		if (this.stepOneVal && this.stepOneVal.patientInfo) {
			this.patientInfo = this.stepOneVal.patientInfo;
			this.form1 = this.stepOneVal.form1;
			this.canNext = true;
		}
		this.consultationHist();
	},
	mounted () {
		// console.log('this=====', this.$route)
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	computed: {
		...mapGetters({
			patientList: 'getPatientList',
			stepOneVal: 'getStepOne'
		})
	},
	methods: {
		onChangeText () {
			this.canNext = true;
			if (!this.form1.diseaseNameOrzz || !this.form1.diseaseDescription) {
				this.canNext = false;
			}
			if (this.form1.diseaseNameOrzz.length > 30) {
				this.form1.diseaseNameOrzz = this.form1.diseaseNameOrzz.substr(0, 30);
			}
			if (this.form1.diseaseDescription.length > 100) {
				this.form1.diseaseDescription = this.form1.diseaseDescription.substr(0, 100);
			}
			if (this.form1.takedMedicine.length > 100) {
				this.form1.takedMedicine = this.form1.takedMedicine.substr(0, 100);
			}
			if (this.form1.takedMedicine.length > 100) {
				this.form1.takedMedicine = this.form1.takedMedicine.substr(0, 100);
			}
			if (this.form1.guominshi.length > 100) {
				this.form1.guominshi = this.form1.guominshi.substr(0, 100);
			}
			if (this.form1.jiwangbingshi.length > 100) {
				this.form1.jiwangbingshi = this.form1.jiwangbingshi.substr(0, 100);
			}
			if (this.form1.hunyushi.length > 100) {
				this.form1.hunyushi = this.form1.hunyushi.substr(0, 100);
			}
		},
		nextStep () {
			if (this.canNext) {
				this.showPopup = true;
			}
		},
		hidePopup () {
			this.showPopup = false;
		},
		toConsultation (type) {
			this.$store.commit('setStep', {
				step: 'stepOne',
				obj: {
					patientInfo: this.patientInfo,
					form1: this.form1
				}
			});
			this.$store.commit('setStep', {
				step: 'stepTwo',
				obj: {}
			});
			this.$store.commit('setStep', {
				step: 'stepThree',
				obj: {
					tongueFaceData: {}
				}
			});
			if (type === 2) {
				this.$root.replace({ path: '/consultation-three' });
			} else {
				if (type === 1) {
					let qa = {};
					if (this.historyData.consultationAnswer) {
						qa = JSON.parse(this.historyData.consultationAnswer);
					}
					this.$store.commit('setStep', {
						step: 'stepTwo',
						obj: {
							qa,
							consultationType: this.historyData.consultationType
						}
					});
					this.$store.commit('setStep', {
						step: 'stepThree',
						obj: {
							tongueFaceData: this.historyData
						}
					});
				}
				this.$root.replace({
					path: '/consultation-two',
					query: {
						...this.$route.query,
						useHistory: type
					}
				});
			}
		},
		onBack () {
			this.showGoBack = true;
		},
		consultationHist () {
			consultationHist({ patientId: this.$route.query.patientId }).then(data => {
				// console.log('data=====', data);
				if (data && data === 'retry') {
					this.mainGetPatients();
				} else if (data) {
					this.hasHistory = true;
					this.historyData = data.history;
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		closeDialog (type) {
			this.$store.commit('setStep', {
				step: 'stepOne',
				obj: {}
			});
			this.$store.commit('setStep', {
				step: 'stepTwo',
				obj: {}
			});
			this.$store.commit('setStep', {
				step: 'stepThree',
				obj: {}
			});
			this.showGoBack = false;
			if (type) {
				this.$root.go('/doctor-detail', { doctorId: this.$route.query.doctorId });
			}
		}
	}
};
</script>
<style lang="less" scoped>
	body {
		overflow: hidden;
	}
	.consu-container {
		// padding-bottom: 1px;
		overflow: hidden;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.consultation {
		// margin-bottom: 72px;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		margin: 0;
		.consultation-content {
			display: flex;
			flex-direction: column;
			overflow: auto;
			flex: 1;
			padding: 0 10px;
		}
	}
	.basic-bg {
		margin-top: 10px;
		background-color: #FFFFFF;
		padding: 0 16px;
	}
	.patient {
		padding-top: 20px;
		h1 {
			font-size: 17px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #232830;
			margin-bottom: 25px;
		}
		ul {
			border-bottom: 1px solid #F2F2F2;
			margin-bottom: 20px;
			li {
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				margin-bottom: 20px;
				label {
					display: inline-block;
					width: 45px;
					color: #7D7D7D;
					margin-right: 15px;
				}
				span {
					color: #232830;
				}
			}
		}
	}
	.pt10 {
		padding-top: 10px;
	}
	.basic-info {
		li {
			border-bottom: 1px solid #F2F2F2;
			padding-bottom: 20px;
			h1 {
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #232830;
				padding-left: 10px;
				margin-top: 15px;
				margin-bottom: 15px;
				i {
					color: #FF8D16;
				}
			}
			.wh {
				justify-content: space-between;
				label {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #7D7D7D;
					margin-right: 7px;
					white-space: nowrap;
				}
				.val {
					width: 95px;
					height: 40px;
					border-radius: 3px;
					border: 1px solid #ACD1FF;
					padding-right: 10px;
					box-sizing: border-box;
					input {
						border: none;
						width: 60px;
						text-align: right;
						padding-right: 8px;
						font-size: 15px;
						box-sizing: border-box;
					}
					span {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #8B8B8B;
						white-space: nowrap;
					}
				}
				.val1 {
					margin-right: 35px;
				}
			}
			.input {
				// width: 300px;
				// height: 40px;
				border-radius: 3px;
				border: 1px solid #ACD1FF;
				font-size: 14px;
				padding-left: 14px;
			}
		}
	}
	.middle {
		margin: 20px 0 15px;
		i {
			width: 22px;
			height: 1px;
		}
		.left {
			background: linear-gradient(63deg, #BEBEBE 0%, #FFFFFF 100%);
		}
		span {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #BEBEBE;
		}
		.right {
			background: linear-gradient(63deg, #FFFFFF 0%, #BEBEBE 100%);
		}
	}
	.btn {
		// position: fixed;
		height: 52px;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #FFFFFF;
		button {
			width: 339px;
			height: 40px;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 21px;
			opacity: 0.5;
			border: none;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
		.enable {
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%) !important;
			opacity: 1 !important;
		}
	}
	.popup-btns {
		padding: 25px 15px;
		background-color: #FFFFFF;
		li {
			height: 55px;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 4px;
			font-size: 15px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			text-align: center;
			line-height: 55px;
			margin-bottom: 15px;
		}
		.disabled {
			opacity: 0.5 !important;
		}
	}
	.cancel {
		height: 52px;
		background: #FFFFFF;
		text-align: center;
		line-height: 52px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #3B3D40;
		margin-top: 10px;
	}
	.back-dialog {
		flex-direction: column;
		padding: 30px 22px 25px;
		width: 290px;
		// height: 120px;
		background: #FFFFFF;
		border-radius: 7px;
		h1 {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #232830;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3B3D40;
			text-align: center;
		}
		.back-btns {
			span {
				display: inline-block;
				width: 112px;
				height: 33px;
				background: #FFFFFF;
				border-radius: 16.5px;
				border: 1px solid #FF6450;
				line-height: 33px;
				margin-top: 20px;
				&:nth-child(1) {
					color: #FF6450;
					margin-right: 16px;
				}
				&:nth-child(2) {
					color: #FFFFFF;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				}
			}
		}
	}
</style>