<!--创建问诊单-->
<template>
	<!-- <div class="consu-container"> -->
		<ul class="header flex f-ai-c f-jc-sa">
			<li :class="['one', 'two', 'three'].indexOf(step) > -1 ? 'active' : ''">患者信息</li>
			<i :class="['two', 'three'].indexOf(step) > -1 ? 'active' : ''">>></i>
			<li :class="['two', 'three'].indexOf(step) > -1 ? 'active' : ''">问诊单问题</li>
			<i :class="'three' === step ? 'active' : ''">>></i>
			<li :class="'three' === step ? 'active' : ''">上传舌面照</li>
		</ul>
	<!-- </div> -->
</template>
<script>

export default {
	name: 'ConsultationHeader',
	data () {
		return {
			step: 'one'
		};
	},
	created () {
		this.step = this.$route.path.split('-')[1];
	}
};
</script>
<style lang="less" scoped>
	/* .consu-container {
		padding-bottom: 1px;
	} */
	.header {
		height: 52px;
		background: url('../../assets/images/consultation.png') no-repeat;
		background-size: 100%;
		border-radius: 0 0 5px 5px;
		padding: 0 34.5px;
		li {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(255,255,255,0.69);
			&.active {
				color: #FFFFFF !important;
			}
		}
		i {
			color: rgba(255,255,255,0.69);
			&.active {
				color: #FFFFFF !important;
			}
		}
	}
</style>