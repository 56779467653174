// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/consultation.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* .consu-container {\n\tpadding-bottom: 1px;\n} */\n.header[data-v-d23b0ec6] {\n  height: 3.25rem;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100%;\n  border-radius: 0 0 0.3125rem 0.3125rem;\n  padding: 0 2.15625rem;\n}\n.header li[data-v-d23b0ec6] {\n  font-size: 0.875rem;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: rgba(255, 255, 255, 0.69);\n}\n.header li.active[data-v-d23b0ec6] {\n  color: #FFFFFF !important;\n}\n.header i[data-v-d23b0ec6] {\n  color: rgba(255, 255, 255, 0.69);\n}\n.header i.active[data-v-d23b0ec6] {\n  color: #FFFFFF !important;\n}\n", ""]);
// Exports
module.exports = exports;
