var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consu-container" },
    [
      _c("v-consultation-header"),
      _c("div", { staticClass: "consultation wrap" }, [
        _c("div", { staticClass: "consultation-content" }, [
          _c("div", { staticClass: "basic-bg" }, [
            _c("div", { staticClass: "patient" }, [
              _c("h1", [_vm._v("患者信息")]),
              _c("ul", [
                _c("li", [
                  _c("label", [_vm._v("姓名")]),
                  _c("span", [_vm._v(_vm._s(_vm.patientInfo.name))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v("性别")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.patientInfo.gender === 1 ? "男" : "女")),
                  ]),
                ]),
                _c("li", [
                  _c("label", [_vm._v("年龄")]),
                  _c("span", [_vm._v(_vm._s(_vm.patientInfo.age))]),
                ]),
                _c("li", [
                  _c("label", [_vm._v("手机号")]),
                  _c("span", [_vm._v(_vm._s(_vm.patientInfo.mobilePhone))]),
                ]),
              ]),
            ]),
            _c("ul", { staticClass: "basic-info" }, [
              _c("li", [
                _c("h1", [_vm._v("身高体重")]),
                _c("div", { staticClass: "wh flex f-ai-c" }, [
                  _c("label", [_vm._v("身高")]),
                  _c("div", { staticClass: "val val1 flex f-ai-c" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.patientInfo.height,
                          expression: "patientInfo.height",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: { value: _vm.patientInfo.height },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.patientInfo,
                            "height",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("span", [_vm._v("cm")]),
                  ]),
                  _c("label", [_vm._v("体重")]),
                  _c("div", { staticClass: "val flex f-ai-c" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.patientInfo.weight,
                          expression: "patientInfo.weight",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: { value: _vm.patientInfo.weight },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.patientInfo,
                            "weight",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("span", [_vm._v("kg")]),
                  ]),
                ]),
              ]),
              _c(
                "li",
                [
                  _vm._m(0),
                  _c("van-field", {
                    staticClass: "input",
                    attrs: { placeholder: "例：糖尿病2年或呕吐3天（2-20字）" },
                    on: { input: _vm.onChangeText },
                    model: {
                      value: _vm.form1.diseaseNameOrzz,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "diseaseNameOrzz", $$v)
                      },
                      expression: "form1.diseaseNameOrzz",
                    },
                  }),
                ],
                1
              ),
              _c(
                "li",
                [
                  _vm._m(1),
                  _c("van-field", {
                    staticClass: "input",
                    attrs: { placeholder: "详细描述您的病情与症状(最多100字)" },
                    on: { input: _vm.onChangeText },
                    model: {
                      value: _vm.form1.diseaseDescription,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "diseaseDescription", $$v)
                      },
                      expression: "form1.diseaseDescription",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._m(2),
          _c("div", { staticClass: "basic-bg" }, [
            _c("ul", { staticClass: "basic-info pt10" }, [
              _c(
                "li",
                [
                  _c("h1", [_vm._v("服用药物")]),
                  _c("van-field", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "请填写当前是否正在服用药物",
                      type: "text",
                    },
                    on: { input: _vm.onChangeText },
                    model: {
                      value: _vm.form1.takedMedicine,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "takedMedicine", $$v)
                      },
                      expression: "form1.takedMedicine",
                    },
                  }),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("h1", [_vm._v("过敏史")]),
                  _c("van-field", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "药物、食物及其他；若没有，请填写“无”",
                      type: "text",
                    },
                    on: { input: _vm.onChangeText },
                    model: {
                      value: _vm.form1.guominshi,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "guominshi", $$v)
                      },
                      expression: "form1.guominshi",
                    },
                  }),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("h1", [_vm._v("既往病史")]),
                  _c("van-field", {
                    staticClass: "input",
                    attrs: {
                      placeholder: "以前得过什么病；若没有，请填写“无”",
                      type: "text",
                    },
                    on: { input: _vm.onChangeText },
                    model: {
                      value: _vm.form1.jiwangbingshi,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "jiwangbingshi", $$v)
                      },
                      expression: "form1.jiwangbingshi",
                    },
                  }),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("h1", [_vm._v("婚育史")]),
                  _c("van-field", {
                    staticClass: "input",
                    attrs: {
                      type: "textarea",
                      placeholder:
                        "已婚、未婚；女性完善（未生育、备孕期、怀孕期、已生育）",
                      rows: "3",
                    },
                    on: { input: _vm.onChangeText },
                    model: {
                      value: _vm.form1.hunyushi,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "hunyushi", $$v)
                      },
                      expression: "form1.hunyushi",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn flex f-jc-c f-ai-c" }, [
          _c(
            "button",
            { class: { enable: _vm.canNext }, on: { click: _vm.nextStep } },
            [_vm._v("下一步")]
          ),
        ]),
      ]),
      _c(
        "van-popup",
        {
          staticStyle: { background: "#F3F3F3" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPopup,
            callback: function ($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup",
          },
        },
        [
          _c("ul", { staticClass: "popup-btns" }, [
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.toConsultation()
                  },
                },
              },
              [_vm._v("新建问诊单")]
            ),
            _vm.hasHistory
              ? _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.toConsultation(1)
                      },
                    },
                  },
                  [_vm._v("引用历史问诊单")]
                )
              : _c("li", { staticClass: "disabled" }, [
                  _vm._v("引用历史问诊单"),
                ]),
          ]),
          _c("div", { staticClass: "cancel", on: { click: _vm.hidePopup } }, [
            _vm._v("取消"),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "flex f-ai-c back-dialog",
          model: {
            value: _vm.showGoBack,
            callback: function ($$v) {
              _vm.showGoBack = $$v
            },
            expression: "showGoBack",
          },
        },
        [
          _c("h1", [_vm._v("提示")]),
          _c("span", [
            _vm._v("是否确认离开此页面？离开后已填写的信息将无法被保存。"),
          ]),
          _c("div", { staticClass: "back-btns" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDialog(1)
                  },
                },
              },
              [_vm._v("确定离开")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v("我再想想")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_c("i", [_vm._v("*")]), _vm._v(" 疾病名称或症状 ")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_c("i", [_vm._v("*")]), _vm._v(" 病情描述 ")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "middle flex f-jc-c f-ai-c" }, [
      _c("i", { staticClass: "left" }),
      _c("span", [_vm._v("以下信息将帮助医生更准确地了解您的病情")]),
      _c("i", { staticClass: "right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }