var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "header flex f-ai-c f-jc-sa" }, [
    _c(
      "li",
      { class: ["one", "two", "three"].indexOf(_vm.step) > -1 ? "active" : "" },
      [_vm._v("患者信息")]
    ),
    _c(
      "i",
      { class: ["two", "three"].indexOf(_vm.step) > -1 ? "active" : "" },
      [_vm._v(">>")]
    ),
    _c(
      "li",
      { class: ["two", "three"].indexOf(_vm.step) > -1 ? "active" : "" },
      [_vm._v("问诊单问题")]
    ),
    _c("i", { class: "three" === _vm.step ? "active" : "" }, [_vm._v(">>")]),
    _c("li", { class: "three" === _vm.step ? "active" : "" }, [
      _vm._v("上传舌面照"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }